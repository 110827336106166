<template>
  <b-row>
    <b-col
      cols="12"
    >
      <b-card>
        <b-row>
          <b-col cols="7">
            <h3><strong>Sua conta</strong></h3>
            <p v-if="planType !== 2">
              Plano Atual: <strong>{{ getCompanyNamePlan }}</strong>
            </p>
            <b-badge
              v-if="activeCompany !== null"
              :variant="activeCompany ? 'light-success' : 'light-danger'"
              class="badge__status mt-1"
            >
              {{ activeCompany ? 'Ativo' : 'Bloqueado' }}
            </b-badge>

            <div class="my-1">
              <b-alert
                v-if="autoRenewal === false && planType === 1"
                variant="danger"
                show
              >
                <h4 class="alert-heading">
                  {{ activeCompany ? 'Plano sem renovação' : 'Ative para continuar utilizando' }}
                </h4>
                <div class="alert-body">
                  {{ activeCompany
                    ? `A renovação automática do seu plano foi desativada. Sua conta ficará ativada até ${fineshIn}. Para continuar utilizando o Indiquei.vc ative novamente seu plano`
                    : 'Para continuar utilizando seu indiquei.vc ative seu plano'
                  }}
                  <b-link
                    class="alert-link"
                    href="javascript:void()"
                    @click="() => this.$router.push({
                      name: 'checkout-credit-card',
                      query: { activeAutoRenew: true }
                    })"
                  >
                    clicando aqui
                  </b-link>
                  .
                </div>
              </b-alert>
            </div>
          </b-col>
          <b-col
            cols="4"
            offset="1"
          >
            <b-row>
              <b-col cols="8">
                <h5>Indicações disponíveis:</h5>
              </b-col>
              <b-col cols="4">
                <h4><strong>{{ indicationsCount }}</strong></h4>
              </b-col>
              <b-col
                v-if="fineshIn"
                cols="8"
              >
                <p>{{ planType === 3 ? 'Data de renovação' : 'Data de vencimento' }}</p>
              </b-col>
              <b-col
                v-if="fineshIn"
                cols="4"
              >
                <p><strong>{{ fineshIn }}</strong></p>
              </b-col>
              <template v-if="planType === 3">
                <b-col
                  cols="8"
                  style="background-color: #eee"
                >
                  <p class="my-1">
                    <strong>Leads excedidos</strong>
                  </p>
                </b-col>
                <b-col
                  cols="4"
                  style="background-color: #eee"
                >
                  <p class="mt-1">
                    <strong>{{ exceededLeads }}</strong>
                  </p>
                </b-col>
                <b-col
                  cols="8"
                >
                  <p>Valor do lead excedido</p>
                </b-col>
                <b-col
                  cols="4"
                >
                  <p><strong>{{ exceededLeadsPrice }}</strong></p>
                </b-col>
                <b-col
                  cols="8"
                >
                  <p><strong>Total a pagar</strong></p>
                </b-col>
                <b-col
                  cols="4"
                >
                  <p><strong>{{ totalPayable }}</strong></p>
                </b-col>
              </template>
              <b-col
                v-if="autoRenewal === false && (planType === 1 || planType === 4)"
                cols="12"
              >
                <b-button
                  variant="primary"
                  class="d-flex justify-content-center"
                  style="width: 100%;"
                  :disabled="$can('edit', 'settingFinancial') === false"
                  @click="() => this.$router.push({
                    name: 'checkout-credit-card',
                    query: { activeAutoRenew: true }
                  })"
                >
                  <span class="align-middle">Ativar Plano</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row
          v-if="autoRenewal === false && (planType === 1 || planType === 4)"
          class="mt-4"
        >
          <b-col cols="12">
            <div
              class="p-4 box-banner-renovacao"
              style="
                background-image: url('/images/banner-renovacao-automatica.png');
                background-size: cover;
                background-position: center right;
                "
            >
              <h4>
                Ative agora sua conta indiquei.vc!
              </h4>
              <p>
                Escolha o pacote ideal para seu negócio e aproveite todos os benefícios que o boca a boca digital pode proporcionar.
              </p>
              <b-button
                class="d-flex justify-content-center btn_ativar mt-2"
                :disabled="$can('edit', 'settingFinancial') === false"
                @click="() => this.$router.push({
                  name: 'checkout-credit-card',
                  query: { activeAutoRenew: true }
                })"
              >
                <span class="align-middle">Ativar Plano</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- informações plano pós pago -->
        <b-row
          v-if="planType === 1 && autoRenewal === true"
          class="mt-4"
        >
          <b-col cols="5">
            <h5><strong>Assinatura e cobrança</strong></h5>
            <b-row>
              <b-col
                cols="6"
                class="mt-1"
              >
                Pacote de leads
              </b-col>
              <b-col
                cols="6"
                class="mt-1"
              >
                <strong>{{ originalIndications }}</strong>
              </b-col>
              <b-col
                cols="6"
                class="mt-1"
              >
                Valor
              </b-col>
              <b-col
                cols="6"
                class="mt-1"
              >
                <strong>{{ planValue }}</strong>
              </b-col>
              <b-col
                cols="6"
                class="mt-1"
              >
                <b-img
                  :src="require(`@/assets/images/svg/${defaultPaymentMethod.brand}.svg`)"
                  alt="Bandeira de cartão"
                  class="flag-icon"
                />
              </b-col>
              <b-col
                cols="6"
                class="mt-1"
              >
                <strong>{{ defaultPaymentMethod.masked_number }}</strong>
              </b-col>
              <b-col
                cols="6"
                class="d-flex mt-2"
              >
                <b-button
                  variant="outline-primary"
                  class="d-flex justify-content-center"
                  style="width: 100%"
                  :disabled="$can('edit', 'settingFinancial') === false"
                  @click="() => alterRecurringPayment()"
                >
                  <span class="align-middle">Cancelar assinatura</span>
                </b-button>
              </b-col>
              <b-col
                cols="6"
                class="d-flex mt-2"
              >
                <b-button
                  variant="primary"
                  class="d-flex justify-content-center ml-1"
                  style="width: 100%"
                  :disabled="$can('edit', 'settingFinancial') === false"
                  @click="() => (this.$router.push({ name: 'update-company-plan' }))"
                >
                  <span class="align-middle">Antecipar renovação</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="6"
            offset="1"
          >
            <h5><strong>Regulamento</strong></h5>
            <p>
              O plano contratado possui o prazo de validade de 30 dias, ao final da vigência seu plano renovará automaticamente, se os leads acabarem antes seu plano ficara indisponível até a data da renovação.
            </p>
          </b-col>
        </b-row>

        <!-- tabela de transações -->
        <b-row class="mt-5 mb-5">
          <b-col cols="12">
            <h4><strong>Resumo das últimas compras</strong></h4>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mt-2"
          >
            <label>Mostar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>pagamentos</label>
          </b-col>
        </b-row>
        <b-table
          ref="refTransactionListTable"
          striped
          responsive
          class="mt-1"
          :items="fetchTransactions"
          :fields="tableColumnsTransactions"
          empty-text="Não existem cobranças cadastradas"
          show-empty
        >
          <template #cell(planName)="data">
            <strong>Crédito de leads de indicação</strong> <br>
            <small>Pacote {{ data.value }}</small>
          </template>

          <template #cell(pricePerLead)="data">
            {{ data.value === 0 ? '-' : data.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
          </template>

          <template #cell(dateBilling)="data">
            {{ data.value }}
          </template>

          <template #cell(dateStatus)="data">
            {{ data.value }}
          </template>

          <template #cell(status)="data">
            <b-badge :variant="resolveTransactionStatusBadge(data.value)">
              {{ data.value }}
            </b-badge>
          </template>
        </b-table>

        <!-- Footer -->
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Exibindo {{ dataMeta.from }} até {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalTransactions"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
        <!-- final tabela de transações -->

        <b-row
          v-if="planType === 3"
          class="mt-5 mb-5"
        >
          <b-col
            cols="12"
            class="text-center mb-2"
          >
            <h4>Está com dúvida? Fale com o seu gerente</h4>
          </b-col>
          <b-col
            cols="4"
            offset="2"
          >
            <a
              href="https://api.whatsapp.com/send?phone=5521977253975&text=Preciso%20de%20ajuda%20com%20o%20meu%20indiquei.vc!"
              target="_blank"
            >
              <div class="box_contact p-1 relative">
                <b-badge
                  variant="light-success"
                  class="badge__contact mt-1"
                >
                  Online
                </b-badge>

                <b-media vertical-align="top">
                  <template #aside>
                    <b-img
                      :src="require('@/assets/images/icons/whatsapp-alternative.png')"
                      blank-color="#ccc"
                      width="40"
                      alt="Whatsapp"
                    />
                  </template>
                  <h4 class="media-heading">
                    <a
                      href="https://api.whatsapp.com/send?phone=5521977253975&text=Preciso%20de%20ajuda%20com%20o%20meu%20indiquei.vc!"
                      target="_blank"
                    >Whatsapp
                    </a>
                  </h4>
                  <b-card-text class="mb-0">
                    Das 9h às 18h, Seg a Sex
                  </b-card-text>
                </b-media>
              </div>
            </a>
          </b-col>
          <b-col
            cols="4"
          >
            <a
              :href="`mailto:comercial@automatuslab.com?&subject=Preciso de ajuda com o indiquei.vc para a empresa: ${fantasyName}&body=Oi, poderia me ajudar com o meu indiquei.vc?`"
              target="_blank"
            >
              <div class="box_contact p-1">
                <b-media vertical-align="top">
                  <template #aside>
                    <b-img
                      :src="require('@/assets/images/icons/email-alternative.png')"
                      blank-color="#ccc"
                      width="40"
                      alt="Email"
                    />
                  </template>
                  <h4 class="media-heading">
                    <a
                      :href="`mailto:comercial@automatuslab.com?&subject=Preciso de ajuda com o indiquei.vc para a empresa: ${fantasyName}&body=Oi, poderia me ajudar com o meu indiquei.vc?`"
                      target="_blank"
                    >Email
                    </a>
                  </h4>
                  <b-card-text class="mb-0">
                    Responderemos em até 48h
                  </b-card-text>
                </b-media>
              </div>
            </a>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BBadge, BButton, BTable, BPagination, BImg, BMedia, BCardText, BAlert, BLink,
} from 'bootstrap-vue'

import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'

import vSelect from 'vue-select'

import toConvertDateTimezoneToConventionalDate from '@/utils/toConvertDateTimezoneToConventionalDate'
import toConvertDateTimezoneToConventionalTime from '@/utils/toConvertDateTimezoneToConvencionalTime'

import { onUnmounted } from '@vue/composition-api'

import jwtDecode from 'jwt-decode'

import transactionStoreModule from '../transactionStoreModule'
import useTransactionList from './useTransactionList'

export default {
  name: 'CompanyPlan',
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BButton,
    BTable,
    BPagination,
    vSelect,
    BImg,
    BMedia,
    BCardText,
    BAlert,
    BLink,
    // BFormInput,
  },
  data() {
    return {
      indicationsCount: 0,
      fineshIn: null,
      activeCompany: null,
      planValue: 0,
      originalIndications: 0,
      totalPayable: 0,
      exceededLeads: 0,
      exceededLeadsPrice: 0,
      autoRenewal: true,
      planType: null, // 1 -> pospago | 2 -> prepago | 3-> enterprise | 4-> Free
      defaultPaymentMethod: {
        brand: 'generic',
        masked_number: 'Carregando...',
      },
    }
  },
  computed: {
    getCompanyNamePlan() {
      if (this.planType === 1) return 'Pós-pago'
      if (this.planType === 2) return 'Pré-pago'
      if (this.planType === 3) return 'Enterprise'
      if (this.planType === 4) return 'Free'
      return ''
    },
    fantasyName() {
      return jwtDecode(window.localStorage.getItem('accessToken')).FantasyName
    },
  },
  mounted() {
    this.getActualPlan()
  },
  methods: {
    getActualPlan() {
      useJwt.getActivePlan()
        .then(response => {
          const finishInDate = response.data.result.plan.fineshIn

          this.indicationsCount = response.data.result.totalLeads
          this.fineshIn = finishInDate ? toConvertDateTimezoneToConventionalDate(finishInDate) : null

          this.activeCompany = response.data.result.activeCompany
          store.commit('company/TOGGLE_STATUS_COMPANY', response.data.result.activeCompany)
          this.planType = response.data.result.planType

          this.autoRenewal = response.data.result.autoRenewal

          this.originalIndications = response.data.result.nextPlan ? response.data.result.nextPlan.originalIndications : 0
          // this.originalIndications = response.data.result.totalLeads

          this.totalPayable = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.result.totalPayable)

          this.exceededLeads = response.data.result.exceededLeads
          this.exceededLeadsPrice = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.result.plan.exceededLeadValue)

          const valueRaw = response.data.result.plan ? response.data.result.plan.planValue : 0
          this.planValue = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valueRaw)

          // caso seja pos pago buscamos a informação do cartão favorito
          if (response.data.result.planType === 1) {
            this.getCompanyPaymentMethod()
          }
        })
        .catch(error => error)
    },
    getCompanyPaymentMethod() {
      useJwt.checkCompanyPaymentMethods()
        .then(response => {
          const result = JSON.parse(response.data.result)
          const defaultPaymentMethodId = result.default_payment_method_id
          const paymentListMethodsRaw = result.payment_methods

          // eslint-disable-next-line prefer-destructuring
          const defaultMethodRaw = paymentListMethodsRaw.filter(element => element.id === defaultPaymentMethodId)[0].data

          this.defaultPaymentMethod = {
            brand: defaultMethodRaw.brand
              .toLowerCase(),
            masked_number: defaultMethodRaw.masked_number || defaultMethodRaw.display_number
              .replaceAll('X', '*')
              .replaceAll('-', ' '),
          }
        })
        .catch(error => error)
    },
    alterRecurringPayment() {
      this.$swal({
        title: 'Tem certeza?',
        text: 'Ao cancelar a renovação automática seus créditos deixam de acumular a cada nova recarga e passam a expirar no vencimento.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, cancelar',
        cancelButtonText: 'Voltar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            useJwt.alterRecurringPayment({
              autoRenewal: false,
            })
              .then(response => {
                this.getActualPlan()
                return response
              })
              .catch(() => {
                this.$swal({
                  title: 'Ocorreu um problema?',
                  text: 'não foi possível cancelar sua assinatura. Tente novamente',
                  icon: 'warning',
                  showCancelButton: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          }
        })
    },
  },
  setup() {
    const {
      tableColumnsTransactions,
      fetchTransactions,
      resolveTransactionStatusBadge,
      refTransactionListTable,
      perPage,
      currentPage,
      totalTransactions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
    } = useTransactionList()

    const convertDate = date => toConvertDateTimezoneToConventionalDate(date)
    const convertDateAndTime = date => toConvertDateTimezoneToConventionalTime(date)

    const INDICATE_APP_STORE_MODULE_NAME = 'app-company-config'
    if (!store.hasModule(INDICATE_APP_STORE_MODULE_NAME)) store.registerModule(INDICATE_APP_STORE_MODULE_NAME, transactionStoreModule)
    onUnmounted(() => {
      if (store.hasModule(INDICATE_APP_STORE_MODULE_NAME)) store.unregisterModule(INDICATE_APP_STORE_MODULE_NAME)
    })

    const tableColumns = [
      { key: 'name', label: 'Perfil', sortable: false },
      { key: 'usersAssociated', label: 'Usuários', sortable: false },
      { key: 'actions', label: 'Ações' },
    ]

    return {
      fetchTransactions,
      refTransactionListTable,
      perPage,
      currentPage,
      totalTransactions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      tableColumnsTransactions,
      resolveTransactionStatusBadge,
      convertDate,
      convertDateAndTime,
    }
  },
}
</script>

<style lang="scss" scoped>

@import '@core/scss/vue/libs/vue-select.scss';

.box-banner-renovacao {
    h4, p {
        color: #FFF;
        font-weight: normal;
    }
    .btn_ativar {
        background-color: #FFF !important;
        color: #07367F !important;
        border-color: #7367F0 !important;
        font-weight: bold;
    }
}

    .flag-icon {
        max-height: 24px !important;
    }

    .box_contact {
        width: 100%;
        border: 1px solid #CCC;
        border-radius: 5px;

        .badge__contact {
            position: absolute;
            top: 0;
            right: 30px;
        }
    }

</style>
