import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import toConvertDateTimezoneToConventionalDate from '@/utils/toConvertDateTimezoneToConventionalDate'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTransactionList() {
  // Use toast
  const toast = useToast()

  const refTransactionListTable = ref(null)

  const tableColumnsTransactions = [
    {
      key: 'planName', label: 'Item', sortable: false,
    },
    {
      key: 'originalIndications', label: 'Leads Contratados', sortable: false,
    },
    // {
    //   key: 'indicationsCount', label: 'Leads disponíveis', sortable: false,
    // },
    {
      key: 'pricePerLead', label: 'Preço por lead', sortable: false,
    },
    {
      key: 'planValue', label: 'Valor', sortable: false, formatter: value => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      key: 'createdIn', label: 'Compra', sortable: false, formatter: value => toConvertDateTimezoneToConventionalDate(value),
    },
    {
      key: 'fineshIn', label: 'Vencimento', sortable: false, formatter: value => toConvertDateTimezoneToConventionalDate(value),
    },
  ]

  const perPage = ref(10)
  const totalTransactions = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('createdIn')
  const isSortDirDesc = ref(false)
  const stageFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refTransactionListTable.value ? refTransactionListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTransactions.value,
    }
  })

  const refetchData = () => {
    refTransactionListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchTransactions = (ctx, callback) => {
    const payload = {
      // q: searchQuery.value,
      // perPage: perPage.value,
      // page: currentPage.value,
      // sortBy: sortBy.value,
      // sortDesc: isSortDirDesc.value,
      // stage: stageFilter.value,
      status: false,
      pageNumber: currentPage.value - 1,
      pageSize: perPage.value,
      sortBy: true,
    }
    store
      .dispatch('app-company-config/fetchTransactions', payload)
      .then(response => {
        if (response.data) {
          // const { transactions, total } = response.data
          const transactions = response.data.result.objectLists[0]

          callback(transactions)
          totalTransactions.value = response.data.result.totalResults
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao carregar a lista de pagamentos',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchTransactions,
    tableColumnsTransactions,
    perPage,
    currentPage,
    totalTransactions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTransactionListTable,

    refetchData,
    stageFilter,
  }
}
